<script>
import appConfig from "@/app.config";
import Layout from "../../../layouts/main";
import PageHeader from "@/components/page-header";
export default {
  page: {
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: {
    Layout,
    PageHeader,
  },
  data() {
    return {
      // global data
      branch_id: null,
      branches: [],
      newAsset: {},
      registerAsset: {},
      // end global data
      fixedAssets: [],
      typesOptions: [],
      currentAssets: [],
      addAsset: [{}],
      page: 1,
      limit: 20,
      tot_pages: 0,
      searchBudget: null,
      BudgetSearchMode: false,
      isDepreciable: false,
      isRegisterDepreciable: false,

      // appprices:[]
    };
  },
  methods: {
    searchFixedAssets() {
      this.BudgetSearchMode = true;
      this.tot_pages = 0;
      this.http
        .post("budget/search", { search: this.searchBudget })
        .then((res) => {
          this.budget = res.data;
        });
    },
    cancelFixedAssetsSearchMode() {
      this.searchBudget = "";
      this.BudgetSearchMode = false;
      this.get(this.page);
    },
    registerFixedAsset() {
      console.log(this.registerAsset);
      this.http
        .post("transactions/register-assets", { register: this.registerAsset })
        .then(() => {
          this.registerAsset = {};
          this.getFixedAssetsPagination(this.Page);
        });
    },
    addFixedAsset() {
      console.log(this.newAsset);
      console.log(this.addAsset);
      console.log(this.typesOptions);

      this.typesOptions.forEach((type) => {
        if (type.id == this.newAsset.type_id) {
          this.newAsset.type_name = type.name_en;
        }
      });
      console.log(this.newAsset);
      this.http
        .post("transactions/fixed-assets", {
          newAsset: this.newAsset,
          addAsset: this.addAsset,
        })
        .then(() => {
          this.newAsset = {};
          this.addAsset = [{}];
          this.getFixedAssetsPagination(this.Page);
        });
    },
    editFixedAsset(fixedAsset) {
      var data = {
        title: "popups.edit",
        inputs: [
          {
            model: "name",
            type: "text",
            label: "fixed_assets.label",
            value: fixedAsset.name,
          },
          {
            model: "name_en",
            type: "text",
            label: "fixed_assets.label_en",
            value: fixedAsset.name_en,
          },
          {
            model: "type_id",
            type: "select",
            options: (() => {
              return this.typesOptions.map((data) => {
                return { value: data.id, label: data.name };
              });
            })(),
            label: "fixed_assets.type",
          },
          {
            model: "initial_book_value",
            type: "text",
            label: "fixed_assets.initial",
            value: fixedAsset.initial_book_value,
          },
          {
            model: "depreciation",
            type: "text",
            label: "fixed_assets.depreciation",
            value: fixedAsset.depreciation,
          },
        ],
        buttons: [
          {
            text: "popups.edit",
            closer: true,
            color: "primary",
            handler: (obj) => {
              this.http.put("fixed-assets", fixedAsset.id, obj).then(() => {
                this.getFixedAssetsPagination(this.Page);
              });
            },
          },
          {
            text: "Cancel",
            closer: true,
            color: "danger",
            handler: () => {
              console.log("Like Clicked");
            },
          },
        ],
      };
      this.popup.modal(data);
    },
    deleteFixedAsset(fixedAsset) {
      var data = {
        title: "popups.delete",
        msg: "popups.delmessage",
      };
      this.popup.confirm(data).then((resp) => {
        if (resp) {
          this.http.delete("fixed-assets", fixedAsset.id).then(() => {
            this.getFixedAssetsPagination(this.Page);
          });
        }
      });
    },
    getFixedAssetsPagination(page) {
      this.http
        .post("fixed-assets/disposed/pagination", {
          limit: this.limit,
          page: page,
        })
        .then((res) => {
          this.Page = page;
          this.TotalPages = Math.ceil(res.tot / this.limit);
          this.fixedAssets = res.data;
        });
    },
    getAccounts() {
      this.http.post("transactions/cash-accounts", {}).then((resp) => {
        this.currentAssets = resp.data;
      });
    },
    changeAddDepreciatoin() {
      this.isDepreciable = this.newAssets.depreciable;
    },
    changeRegisterDepreciatoin() {
      this.isRegisterDepreciable = this.registerAssetAssets.depreciable;
    },
    transferAsset(asset) {
      let locale = this.$i18n.locale;
      let self = this;
      this.http
        .post("branches/execlude", { asse_brancht_id: asset.branch_id })
        .then((res) => {
          this.branches = res.data;
          var data = {
            title: "fixed_assets.transfer",
            inputs: [
              {
                model: "to_branch_id",
                type: "select",
                options: (() => {
                  return this.branches.map((data) => {
                    return {
                      value: data.id,
                      label: locale == "ar" ? data.name : data.name_en,
                    };
                  });
                })(),
                label: "fixed_assets.transfer_to",
              },
            ],
            buttons: [
              {
                text: "fixed_assets.transfer",
                closer: true,
                color: "primary",
                handler: (obj) => {
                  obj.from_branch_id = asset.branch_id;
                  this.http
                    .put("fixed-assets/transfer", asset.id, obj)
                    .then((res) => {
                      self.branches = res.data;
                      self.getFixedAssetsPagination(self.page);
                    });
                },
              },
              {
                text: "Cancel",
                closer: true,
                color: "danger",
                handler: () => {
                  console.log("Like Clicked");
                },
              },
            ],
          };
          this.popup.modal(data);
        });
    },
    getFixedAssetTypesOptions() {
      this.http.get("fixed-assets/types").then((resp) => {
        this.typesOptions = resp.data;
      });
    },
    sellFixedAsset(asset) {
      let locale = this.$i18n.locale;
      this.http
        .post("fixed-assets/process-disposal", { id: asset.id })
        .then((res) => {
          console.log(res.status);
          var data = {
            title: `${locale == "ar" ? "القيمة الحالية" : "Current Value"} ${
              res.data[0]?.remaining_value
            }`,
            inputs: [
              {
                model: "value",
                type: "text",
                label: "fixed_assets.sell_value",
              },
            ],
            buttons: [
              {
                text: "fixed_assets.disposal",
                closer: true,
                color: "primary",
                handler: (obj) => {
                  obj.name = asset.name;
                  obj.name_en = asset.name_en;
                  obj.level_three_chart_of_account_id =
                    asset.level_three_chart_of_account_id;
                  this.http.post("fixed-assets/disposal", obj).then(() => {
                    this.getFixedAssetsPagination(this.Page);
                  });
                },
              },
              {
                text: "Cancel",
                closer: true,
                color: "danger",
                handler: () => {
                  console.log("Like Clicked");
                },
              },
            ],
          };
          this.popup.modal(data);
        });
    },
    refresh() {
      this.getFixedAssetsPagination(this.page);
    },
  },
  created() {
    const current_user = JSON.parse(localStorage.currentUser);
    this.branch_id = current_user.branch_id;
    this.getFixedAssetsPagination(1);
    this.getFixedAssetTypesOptions();
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="$t('fixed_assets.disposed')" />
    <div :dir="$i18n.locale == 'ar' ? 'ltr' : 'ltr'">
      <div class="card">
        <div class="card-body">
          <div class="row mb-4">
            <div class="col-6">
              <div class="search-box chat-search-box w-50">
                <div class="position-relative">
                  <input
                    @keyup="search()"
                    type="text"
                    class="form-control fa-lg text-light"
                    :dir="$i18n.locale == 'ar' ? 'rtl' : 'ltr'"
                    :placeholder="$t('popups.search')"
                    v-model="searchModel"
                    style="
                      background-color: #2a3042 !important;
                      wdith: 250px !important;
                    "
                  />
                  <i class="bx bx-search-alt search-icon text-light"></i>
                </div>
              </div>
            </div>
          </div>
          <div class="table-responsive">
            <table
              :dir="$i18n.locale == 'ar' ? 'rtl' : 'ltr'"
              class="table table-striped table-hover mb-0"
            >
              <thead>
                <tr
                  class="text-light"
                  style="background-color: #2a3042 !important"
                >
                  <th scope="col">{{ $t("fixed_assets.id") }}</th>
                  <th scope="col">{{ $t("fixed_assets.label") }}</th>
                  <th scope="col">{{ $t("fixed_assets.type") }}</th>
                  <th scope="col">{{ $t("fixed_assets.location") }}</th>
                  <th scope="col">{{ $t("fixed_assets.initial") }}</th>
                  <th scope="col">{{ $t("fixed_assets.depreciable") }}</th>
                  <th scope="col">{{ $t("fixed_assets.rate") }}</th>
                  <th scope="col">{{ $t("fixed_assets.created") }}</th>
                  <th scope="col">{{ $t("fixed_assets.updated") }}</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(fixedAsset, index) in fixedAssets"
                  :key="fixedAsset"
                  class=""
                >
                  <td>{{ index + 1 }}</td>
                  <td>
                    {{
                      $i18n.locale == "ar"
                        ? fixedAsset?.name
                        : fixedAsset?.name_en
                    }}
                  </td>
                  <td>
                    {{
                      $i18n.locale == "ar"
                        ? fixedAsset?.level_three_chart_of_accounts
                            ?.level_two_chart_of_account?.name
                        : fixedAsset?.level_three_chart_of_accounts
                            ?.level_two_chart_of_account?.name_en
                    }}
                  </td>
                  <td>
                    {{
                      $i18n.locale == "ar"
                        ? fixedAsset?.branch.name
                        : fixedAsset?.branch.name_en
                    }}
                  </td>
                  <td>{{ fixedAsset?.initial_book_value }}</td>
                  <td>
                    {{
                      fixedAsset?.depreciable
                        ? $t("fixed_assets.yes")
                        : $t("fixed_assets.yes")
                    }}
                  </td>
                  <td>
                    {{
                      fixedAsset?.depreciation_rate
                        ? fixedAsset?.depreciation_rate
                        : "-"
                    }}
                  </td>
                  <td>{{ fixedAsset?.created.split("T")[0] }}</td>
                  <td>{{ fixedAsset?.updated.split("T")[0] }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <!-- end  table -->

      <!--end-->
      <!--Start Add Modal-->
      <div
        class="modal fade"
        id="addModal"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
        :dir="$i18n.locale == 'ar' ? 'rtl' : ''"
      >
        <div class="modal-dialog">
          <div class="modal-content">
            <form @submit.prevent="addFixedAsset()">
              <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">
                  {{ $t("popups.add") }}
                </h5>
                <button
                  type="button"
                  class="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  style="margin: 0"
                ></button>
              </div>

              <div class="modal-body">
                <div class="row purchase-container">
                  <div class="row d-flex align-items-center mb-2">
                    <div class="col">
                      <label for="asset_id">{{
                        $t("fixed_assets.label")
                      }}</label>
                      <input
                        v-model="newAsset.name"
                        type="text"
                        class="form-control mb-2"
                        :placeholder="$t('fixed_assets.label')"
                      />
                    </div>
                  </div>
                  <div class="row d-flex align-items-center">
                    <div class="col">
                      <label for="asset_id">{{
                        $t("fixed_assets.label_en")
                      }}</label>
                      <input
                        v-model="newAsset.name_en"
                        type="text"
                        class="form-control mb-2"
                        :placeholder="$t('fixed_assets.label_en')"
                      />
                    </div>
                  </div>
                  <div class="row d-flex align-items-center mb-2">
                    <div class="col">
                      <label for="asset_id">{{
                        $t("fixed_assets.type")
                      }}</label>

                      <select
                        v-model="newAsset.type_id"
                        class="form-select mb-2"
                        id="account_id"
                        :placeholder="$t('fixed_assets.type')"
                      >
                        <option
                          v-for="options in typesOptions"
                          :key="options"
                          :value="options?.id"
                        >
                          {{
                            $i18n.locale == "ar"
                              ? options?.name
                              : options?.name_en
                          }}
                        </option>
                      </select>
                    </div>
                  </div>
                  <div class="row d-flex align-items-center">
                    <div class="col">
                      <label for="asset_id">{{
                        $t("fixed_assets.initial")
                      }}</label>
                      <input
                        v-model="newAsset.initial_book_value"
                        type="text"
                        class="form-control mb-2"
                        :placeholder="$t('fixed_assets.initial')"
                      />
                    </div>
                  </div>
                  <div class="row d-flex align-items-center">
                    <div class="col">
                      <label for="asset_id">{{
                        $t("fixed_assets.depreciable")
                      }}</label>
                      <select
                        required
                        class="form-select mb-2"
                        v-model="newAsset.depreciable"
                        id=""
                      >
                        <option value="true">
                          {{ $t("fixed_assets.yes") }}
                        </option>
                        <option value="false">
                          {{ $t("fixed_assets.no") }}
                        </option>
                      </select>
                    </div>
                  </div>
                  <div class="row d-flex align-items-center">
                    <div class="col">
                      <label for="asset_id">{{
                        $t("fixed_assets.salvage_value")
                      }}</label>
                      <input
                        v-model="newAsset.salvage_value"
                        type="text"
                        class="form-control mb-2"
                        :placeholder="$t('fixed_assets.salvage_value')"
                      />
                    </div>
                  </div>
                  <div class="row d-flex align-items-center">
                    <div class="col">
                      <label for="asset_id">{{
                        $t("fixed_assets.useful_life_years")
                      }}</label>
                      <input
                        v-model="newAsset.useful_life_years"
                        type="number"
                        class="form-control mb-2"
                        :placeholder="$t('fixed_assets.useful_life_years')"
                      />
                    </div>
                  </div>
                  <div class="row d-flex align-items-center">
                    <div class="col">
                      <label for="asset_id">{{
                        $t("fixed_assets.installation_date")
                      }}</label>
                      <input
                        v-model="newAsset.installation_date"
                        type="date"
                        class="form-control mb-2"
                        :placeholder="$t('fixed_assets.installation_date')"
                      />
                    </div>
                  </div>
                  <div>
                    <div class="row d-flex align-items-center">
                      <div class="col">
                        <label for="asset_id">{{
                          $t("popups.chose_salary_options")
                        }}</label>
                      </div>
                      <div class="col d-flex justify-content-end">
                        <button
                          v-if="addAsset.length < 3"
                          type="button"
                          @click="addAsset.push({})"
                          class="btn float-end"
                        >
                          <span
                            class="bx bxs-plus-circle float-end fa-2x"
                            style="color: #2a3042 !important"
                          ></span>
                        </button>
                      </div>
                    </div>
                    <div v-for="(asset, index) in addAsset" :key="asset">
                      <div class="row my-3">
                        <div class="d-flex justify-content-between">
                          <select
                            v-model="asset.asset_id"
                            class="form-select mb-2"
                            id="asset_id"
                            :placeholder="$t('emps.account')"
                          >
                            <option
                              v-for="assets in currentAssets"
                              :key="assets"
                              :value="assets?.id"
                            >
                              {{
                                $i18n.locale == "ar"
                                  ? assets?.name
                                  : assets?.name_en
                              }}
                            </option>
                          </select>
                          <input
                            v-model="asset.value"
                            type="number"
                            class="form-control mb-2 mx-1"
                            :placeholder="$t('emps.amount')"
                          />
                          <span class="mx-2">
                            <button
                              class="btn btn-danger"
                              @click="deleteOption(index)"
                              v-if="index > 0"
                            >
                              <i class="bx bx-trash"></i>
                            </button>
                            <button v-else disabled class="btn btn-danger">
                              <i class="bx bx-trash"></i>
                            </button>
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                class="modal-footer"
                :dir="$i18n.locale == 'ar' ? 'rtl' : 'ltr'"
              >
                <button type="submit" class="btn btn-primary">
                  {{ $t("popups.add") }}
                </button>
                <button
                  data-bs-dismiss="modal"
                  type="button"
                  class="btn btn-danger"
                >
                  {{ $t("popups.cancel") }}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
      <!--End Add Modal-->

      <!--Start register Modal-->
      <div
        class="modal fade"
        id="registerModal"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
        :dir="$i18n.locale == 'ar' ? 'rtl' : ''"
      >
        <div class="modal-dialog">
          <div class="modal-content">
            <form @submit.prevent="registerFixedAsset()">
              <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">
                  {{ $t("fixed_assets.register") }}
                </h5>
                <button
                  type="button"
                  class="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  style="margin: 0"
                ></button>
              </div>

              <div class="modal-body">
                <div class="row purchase-container">
                  <div class="row d-flex align-items-center mb-2">
                    <div class="col">
                      <label for="asset_id">{{
                        $t("fixed_assets.label")
                      }}</label>
                      <input
                        v-model="registerAsset.name"
                        type="text"
                        class="form-control mb-2"
                        :placeholder="$t('fixed_assets.label')"
                      />
                    </div>
                  </div>
                  <div class="row d-flex align-items-center">
                    <div class="col">
                      <label for="asset_id">{{
                        $t("fixed_assets.label_en")
                      }}</label>
                      <input
                        v-model="registerAsset.name_en"
                        type="text"
                        class="form-control mb-2"
                        :placeholder="$t('fixed_assets.label_en')"
                      />
                    </div>
                  </div>
                  <div class="row d-flex align-items-center mb-2">
                    <div class="col">
                      <label for="asset_id">{{
                        $t("fixed_assets.type")
                      }}</label>

                      <select
                        v-model="registerAsset.type_id"
                        class="form-select mb-2"
                        id="account_id"
                        :placeholder="$t('fixed_assets.type')"
                      >
                        <option
                          v-for="options in typesOptions"
                          :key="options"
                          :value="options?.id"
                        >
                          {{ options?.name }}
                        </option>
                      </select>
                    </div>
                  </div>
                  <div class="row d-flex align-items-center">
                    <div class="col">
                      <label for="asset_id">{{
                        $t("fixed_assets.initial")
                      }}</label>
                      <input
                        v-model="registerAsset.initial_book_value"
                        type="text"
                        class="form-control mb-2"
                        :placeholder="$t('fixed_assets.initial')"
                      />
                    </div>
                  </div>
                  <div class="row d-flex align-items-center">
                    <div class="col">
                      <label for="asset_id">{{
                        $t("fixed_assets.depreciable")
                      }}</label>
                      <select
                        required
                        class="form-select mb-2"
                        v-model="registerAsset.depreciable"
                        id=""
                      >
                        <option value="true">
                          {{ $t("fixed_assets.yes") }}
                        </option>
                        <option value="false">
                          {{ $t("fixed_assets.no") }}
                        </option>
                      </select>
                    </div>
                  </div>
                  <div class="row d-flex align-items-center">
                    <div class="col">
                      <label for="asset_id">{{
                        $t("fixed_assets.salvage_value")
                      }}</label>
                      <input
                        v-model="registerAsset.salvage_value"
                        type="text"
                        class="form-control mb-2"
                        :placeholder="$t('fixed_assets.salvage_value')"
                      />
                    </div>
                  </div>
                  <div class="row d-flex align-items-center">
                    <div class="col">
                      <label for="asset_id">{{
                        $t("fixed_assets.useful_life_years")
                      }}</label>
                      <input
                        v-model="registerAsset.useful_life_years"
                        type="number"
                        class="form-control mb-2"
                        :placeholder="$t('fixed_assets.useful_life_years')"
                      />
                    </div>
                  </div>
                  <div class="row d-flex align-items-center">
                    <div class="col">
                      <label for="asset_id">{{
                        $t("fixed_assets.installation_date")
                      }}</label>
                      <input
                        v-model="registerAsset.installation_date"
                        type="date"
                        class="form-control mb-2"
                        :placeholder="$t('fixed_assets.installation_date')"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div
                class="modal-footer"
                :dir="$i18n.locale == 'ar' ? 'rtl' : 'ltr'"
              >
                <button type="submit" class="btn btn-primary">
                  {{ $t("popups.add") }}
                </button>
                <button
                  data-bs-dismiss="modal"
                  type="button"
                  class="btn btn-danger"
                >
                  {{ $t("popups.cancel") }}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
      <!--End register Modal-->
      <!--   Apps  Pagination     -->
      <div>
        <ul class="pagination pagination-rounded justify-content-center mb-2">
          <li class="page-item" :class="{ disabled: page == 1 }">
            <a
              class="page-link"
              href="javascript: void(0);"
              @click="get(page - 1)"
              aria-label="Previous"
            >
              <i class="mdi mdi-chevron-left"></i>
            </a>
          </li>
          <li
            class="page-item"
            :class="{ active: p == page }"
            v-for="p in tot_pages"
            :key="p"
          >
            <a class="page-link" href="javascript: void(0);" @click="get(p)">{{
              p
            }}</a>
          </li>

          <li class="page-item" :class="{ disabled: page == total_pages }">
            <a
              class="page-link"
              href="javascript: void(0);"
              @click="get(page + 1)"
              aria-label="Next"
            >
              <i class="mdi mdi-chevron-right"></i>
            </a>
          </li>
        </ul>
      </div>
    </div>
  </Layout>
</template>

<style scoped>
.purchase-container {
  background: #fafafa;
  margin: 1rem;
  border-radius: 0.5rem;
  padding: 1rem;
}
</style>
